import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/allstar/layout"
import SectionHero from "../components/allstar/resource/badges/section-hero"
import SectionBody from "../components/allstar/resource/badges/section-body"

const BadgesPage = props => {
  return (
    <Layout location={props.location} title={"Badges"} isResourceSite>
      <SectionHero />
      <SectionBody data={props.data} />
    </Layout>
  )
}

export default BadgesPage

export const query = graphql`
  query badgesQuery {
    allFile(filter: { relativeDirectory: { eq: "allstar/badges" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

import React from "react"
import {
  Flex,
  Heading,
  Box,
  Link,
  ListItem,
  Text,
  OrderedList,
} from "@chakra-ui/react"

import { StaticImage } from "gatsby-plugin-image"

import {
  ALL_STAR_MAIL_LINK,
  ALL_STAR_PAGES_URL,
} from "../../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
  },
  "inner-box": {
    margin: "0 auto",
    my: { base: "20px", md: "20px", lg: "60px" },
    width: "100%",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: { base: "flex-start", md: "flex-start", lg: "center" },
  },
  "left-box": {
    width: { base: "100%", md: "100%", lg: "576px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    mr: { base: "0px", md: "0px", lg: "40px" },
  },
  "right-box": {
    mr: { base: "0", md: "0", lg: "104px" },
    width: { base: "100%", md: "100%", lg: "360px" },
  },
  "heading-box": {
    width: "100%",
    mt: { base: "28px", md: "28px", lg: "0px" },
  },
  heading: {
    fontSize: { base: "32px", md: "32px", lg: "40px" },
    fontWeight: 700,
    lineHeight: { base: "40px", md: "40px", lg: "48px" },
    textAlign: "center",
    letterSpacing: "0em",
  },
  "sub-heading": {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 700,
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2D3748",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "allstar-text": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#129459",
  },
  hero: {
    mt: "24px",
    width: "140px",
  },
}

const SectionHero = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["left-box"]}>
        <Box sx={styles["heading-box"]}>
          <Heading sx={styles["sub-heading"]} mt="12px">
            Congratulations again on being named to the elite list of Healthcare
            Research All-Stars for 2024!
          </Heading>
        </Box>

        <Text sx={styles.sx} mt="24px">
          To celebrate your achievement, we've developed a selection of badges
          for use on websites, social media, and other digital platforms. You
          can explore and select the badges via the links provided below. Usage
          of these badges is pre-approved with two requests:
        </Text>

        <OrderedList sx={styles.text} mt="24px">
          <ListItem>
            Please do not post badge(s) publicly until{" "}
            <strong>Wednesday, April 3, 2024</strong>
          </ListItem>
          <ListItem>
            Badges posted in public media (websites, social media, etc.) should
            include a link to the{" "}
            <Link
              sx={styles.link}
              isExternal
              href={"/" + ALL_STAR_PAGES_URL.HOME}
            >
              All-Stars Page
            </Link>
            . When you do this, please notify us at{" "}
            <Link sx={styles.link} href={ALL_STAR_MAIL_LINK}>
              Research.AllStars@avantgardehealth.com
            </Link>{" "}
            and we’ll update your listing with a reciprocal link back to your
            website.
          </ListItem>
        </OrderedList>
      </Flex>
      <Box sx={styles["right-box"]}>
        <Flex flexDirection="column" alignItems="center">
          <Heading as="h2" sx={styles["allstar-text"]}>
            Healthcare Research All-Stars
          </Heading>
          <Heading as="h1" sx={styles.heading} mt="8px">
            Badges
          </Heading>
          <Box sx={styles.hero}>
            <StaticImage
              src="../../../images/allstar/Healthcare Research All-Stars 2024 - Logo.png"
              alt="Healthcare Research All-Stars Logo"
              placeholder="blurred"
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  </Box>
)

export default SectionHero

import React, { useState } from "react"
import {
  Box,
  Flex,
  Text,
  Link,
  Icon,
  Grid,
  Image,
  useDisclosure,
} from "@chakra-ui/react"
import { IoMdDownload } from "react-icons/io"

import { GatsbyImage } from "gatsby-plugin-image"

import BadgesDrawer from "./badges-drawer"

import {
  BADGES,
  PERCENTILES,
  ALL_STAR_MAIL_LINK,
} from "../../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
    bg: "#F4F7FF",
    justifyContent: "center",
  },
  content: {
    my: { base: "24px", md: "24px", lg: "60px" },
    maxWidth: "1120px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  note: {
    fontSize: "16px",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#6E7E99",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2D3748",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "badge-heading": {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21px",
  },
  "badge-item": {
    flexDirection: "column",
    alignItems: "center",
    bg: "white",
    py: "12px",
    px: "20px",
    cursor: "pointer",
    borderRadius: "4px",
    height: { base: "auto", md: "auto", lg: "150px" },
  },
  "badge-text": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#3399FF",
    textAlign: "center",
    maxW: { base: "auto", md: "auto", lg: "160px" },
  },
}

const SectionBody = props => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  const [currentBadge, setCurrentBadge] = useState({
    name: "",
    [PERCENTILES.TOP1]: null,
    [PERCENTILES.TOP3]: null,
    [PERCENTILES.TOP5]: null,
  })

  const filteredBadges = BADGES.map(badge => {
    const item = props.data.allFile.edges.find(
      item => item.node.name === badge.image
    )
    if (item) {
      return {
        ...item,
        specialty: badge.name,
      }
    }
    return null
  }).filter(Boolean)

  const openDrawer = (imageName, specialty) => {
    const one = props.data.allFile.edges.find(
      item => item.node.name === imageName + " 1%"
    )
    const three = props.data.allFile.edges.find(
      item => item.node.name === imageName + " 3%"
    )
    const five = props.data.allFile.edges.find(
      item => item.node.name === imageName + " 5%"
    )

    setCurrentBadge({
      ...currentBadge,
      name: specialty,
      [PERCENTILES.TOP1]: one,
      [PERCENTILES.TOP3]: three,
      [PERCENTILES.TOP5]: five,
    })

    onOpen()
  }

  return (
    <Flex sx={styles.root}>
      <Box sx={styles.content}>
        <Box>
          <Flex alignItems="center">
            <Icon as={IoMdDownload} w="24px" h="24px" mr="4px" />
            <Text sx={styles.heading}>Download Badge</Text>
          </Flex>
          <Text sx={styles.text} mt="8px">
            To download a copy of your badge(s) simply follow the links below.
          </Text>
        </Box>

        <Text sx={styles["badge-heading"]} mt="36px">
          2024 Healthcare Research All-Stars: Hospitals
        </Text>

        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap="20px"
          mt="20px"
        >
          <Flex
            sx={styles["badge-item"]}
            key={filteredBadges?.[0]?.specialty}
            onClick={() =>
              openDrawer(
                filteredBadges?.[0]?.node.name,
                filteredBadges?.[0]?.specialty
              )
            }
            ref={btnRef}
          >
            <Image
              as={GatsbyImage}
              alt={filteredBadges?.[0]?.specialty}
              image={filteredBadges?.[0]?.node.childImageSharp?.gatsbyImageData}
              w="52px"
            />
            <Link mt="12px" sx={styles["badge-text"]}>
              Overall Hospital All&#x2011;Stars
            </Link>
          </Flex>
        </Grid>

        <Text sx={styles["badge-heading"]} mt="36px">
          2024 Healthcare Research All-Stars: Specialties
        </Text>
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap="20px"
          mt="20px"
        >
          {filteredBadges.map((badge, index) => {
            if (index === 0) {
              return null
            }
            return (
              <Flex
                sx={styles["badge-item"]}
                key={badge.specialty}
                onClick={() => openDrawer(badge.node.name, badge.specialty)}
                ref={btnRef}
              >
                <Image
                  as={GatsbyImage}
                  alt={badge?.specialty}
                  image={badge.node.childImageSharp?.gatsbyImageData}
                  w="52px"
                />
                <Link mt="12px" sx={styles["badge-text"]}>
                  {badge.specialty}
                </Link>
              </Flex>
            )
          })}
        </Grid>
        <Text sx={styles.text} mt="20px" maxW="590px">
          If you have any questions or difficulty with your badge(s), please
          contact us at{" "}
          <Link sx={styles.link} href={ALL_STAR_MAIL_LINK}>
            Research.All-Stars@avantgardehealth.com
          </Link>
          .
        </Text>

        <BadgesDrawer
          isOpen={isOpen}
          onClose={onClose}
          currentBadge={currentBadge}
        />
      </Box>
    </Flex>
  )
}

export default SectionBody

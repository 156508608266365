import React from "react"
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  Link,
  Heading,
  Image,
  Button,
  Divider,
} from "@chakra-ui/react"

import { GatsbyImage } from "gatsby-plugin-image"

import { IoMdDownload } from "react-icons/io"

import {
  PERCENTILES,
  ALL_STAR_MAIL_LINK,
} from "../../../../constants/constants"

const styles = {
  root: {
    width: "100%",
  },
  container: {
    my: "60px",
    mx: { base: "16px", md: "60px", lg: "60px" },
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2D3748",
    alignItems: "center",
    textAlign: "left",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
    textDecoration: "underline",
    "&:hover,&:active,&:focus": {
      textDecoration: "underline",
    },
  },
  title: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    fontWeight: 700,
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    color: "#2D3748",
  },
  "sub-heading": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#2D3748",
  },
  "image-container": {
    w: "100%",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #D9DCE6",
  },
}

const BadgesDrawer = ({ onClose, isOpen, currentBadge }) => {
  const handleDownload = percentile => {
    const link = document.createElement("a")
    link.href =
      currentBadge?.[
        percentile
      ]?.node?.childImageSharp?.gatsbyImageData.images.fallback.src
    link.download = currentBadge?.[percentile]?.node?.name || "All-Star Badge"
    link.click()
    link.remove()
  }

  return (
    <Box sx={styles.root}>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p="0px">
            <Box sx={styles.container}>
              <Heading sx={styles.title}>Download Badge</Heading>
              <Text sx={styles.text} mt="8px">
                Please download the badge that corresponds with your position in
                the Research All-Stars list (Top 1%, Top 3%, Top 5%).
              </Text>
              <Text sx={styles.text}>
                If you have any questions or are unsure which badge is
                appropriate, please contact us at{" "}
                <Link sx={styles.link} href={ALL_STAR_MAIL_LINK}>
                  Research.All-Stars@avantgardehealth.com
                </Link>
                .
              </Text>

              <Text sx={styles["sub-heading"]} mt="40px">
                {currentBadge?.name || ""}
              </Text>

              <Box mt="20px">
                <Text>Top 1%</Text>
                <Flex sx={styles["image-container"]} mt="12px">
                  <Image
                    as={GatsbyImage}
                    alt={currentBadge?.[PERCENTILES.TOP1]?.node?.name}
                    image={
                      currentBadge?.[PERCENTILES.TOP1]?.node?.childImageSharp
                        ?.gatsbyImageData
                    }
                    w="175px"
                    mt="20px"
                  />
                  <Divider mt="20px" />
                  <Button
                    rightIcon={<IoMdDownload />}
                    color="#129459"
                    variant="outline"
                    borderColor="#129459"
                    onClick={() => {
                      handleDownload(PERCENTILES.TOP1)
                    }}
                    my="20px"
                  >
                    Download
                  </Button>
                </Flex>
              </Box>

              <Box mt="32px">
                <Text>Top 3%</Text>
                <Flex sx={styles["image-container"]} mt="12px">
                  <Image
                    as={GatsbyImage}
                    alt={currentBadge?.[PERCENTILES.TOP3]?.node?.name}
                    image={
                      currentBadge?.[PERCENTILES.TOP3]?.node?.childImageSharp
                        ?.gatsbyImageData
                    }
                    w="175px"
                    mt="20px"
                  />
                  <Divider mt="20px" />
                  <Button
                    rightIcon={<IoMdDownload />}
                    color="#129459"
                    variant="outline"
                    borderColor="#129459"
                    onClick={() => {
                      handleDownload(PERCENTILES.TOP3)
                    }}
                    my="20px"
                  >
                    Download
                  </Button>
                </Flex>
              </Box>

              <Box mt="32px">
                <Text>Top 5%</Text>
                <Flex sx={styles["image-container"]} mt="12px">
                  <Image
                    as={GatsbyImage}
                    alt={currentBadge?.[PERCENTILES.TOP5]?.node?.name}
                    image={
                      currentBadge?.[PERCENTILES.TOP5]?.node?.childImageSharp
                        ?.gatsbyImageData
                    }
                    w="175px"
                    mt="20px"
                  />
                  <Divider mt="20px" />
                  <Button
                    rightIcon={<IoMdDownload />}
                    color="#129459"
                    variant="outline"
                    borderColor="#129459"
                    onClick={() => {
                      handleDownload(PERCENTILES.TOP5)
                    }}
                    my="20px"
                  >
                    Download
                  </Button>
                </Flex>
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default BadgesDrawer
